import React from "react";
import styles from "../../styles/section.module.scss";
// import SliderProducts from "../SliderProducts";
import loadable from "@loadable/component";
const SliderProducts = loadable(() => import("../SliderProducts"));

export default function ShopSection() {
  return (
    <div className={styles.containerS}>
      <div className={styles.imageContainerS}>
        <h1 className={styles.titleS}>Shop</h1>
        <SliderProducts />
      </div>
    </div>
  );
}
