import React from "react";
import styles from "./../styles/section.module.scss";

export default function FormSuccess() {
  return (
    <div className={styles.formSucContainer}>
      <h1 className={styles.titleSucc}>Gracias por tu respuesta</h1>
      <h2 className={styles.subSucc}>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
        nonummy nibh euismod tincidunt ut laoreet dolore magna ali
      </h2>
    </div>
  );
}
