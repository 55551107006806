import styles from "../styles/home-banner.module.scss";
import React, { useEffect, useState } from "react";
import title from "../images/home-title.png";
import cans from "../images/home-cans.png";
import cansMobile from "../images/home-cans-mobile.png";
import { IsMobileDevice } from "../utils/functions";

const HomeBanner = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    return setIsMobile(IsMobileDevice());
  });

  return (
    <div className={styles.container}>
      <div className={styles.imagesContainer}>
        <img
          alt={"latas"}
          src={isMobile ? cansMobile : cans}
          className={styles.can}
        />
        <img alt={"cerveza 27"} src={title} className={styles.title} />
      </div>
    </div>
  );
};

export default HomeBanner;
