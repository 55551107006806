import React from "react";
import styles from "../../styles/section.module.scss";

export default function AgriculturaRegenerativaSection() {
  return (
    <div className={styles.containerA}>
      <div className={styles.imageContainerA}>
        <div className={styles.contentA}>
          <h1 className={styles.titleA}>
            Agricultura Regenerativa <p>(WTF?!)</p>
          </h1>
          <p className={styles.subtitleA}>
            Una cerveza de ciudad hecha con amor por la tierra
          </p>
          <p className={styles.subtitleB}>
            No tenías por qué saberlo, pero hace tiempo venimos trabajando en
            que nuestra Malta provenga de la Agricultura Regenerativa: es algo
            transformador y muy bueno para todos! <br />
            <br /> La Agricultura Regenerativa es un proceso de producción
            agropecuaria enfocado en la recuperación de los procesos vitales de
            los ecosistemas a partir de la combinación de un conjunto de
            técnicas como la rotación de producción de cultivos y ganadería
            dentro de una misma parcela, la labranza mínima y la reducción
            progresiva en el uso de agroquímicos hasta su eliminación
            definitiva.
            <br />
            <br /> Este método permite recuperar la fertilidad de los suelos
            desertificados y mantener vivos a los microorganismos que los
            habitan, favoreciendo la absorción de mayores cantidades de dióxido
            de carbono, generando más oxígeno y nutrientes, y de esa manera,
            cultivos exponencialmente más saludables.
            <br />
            <br />
            Desde 27 promovemos y lideramos su aplicación apoyando a los
            productores garantizándoles la compra de toda su producción a largo
            plazo y pagándoles mejores precios para que puedan cubrir la
            inversión necesaria para trabajar con este método. Lo que comenzó
            con 570 hectáreas, en tan solo un año se expandieron a 667 hectáreas
            . Esto demuestra y fortalece nuestro compromiso, mejorando la
            calidad de la malta que utilizamos en nuestra cerveza.
            <br />
            <br />
            Al elegir 27 estás colaborando con la regeneración de los suelos y
            la expansión de un nuevo presente para la agricultura.
          </p>
        </div>
      </div>
    </div>
  );
}
