import React, { useEffect, useState } from "react";
import styles from "../../styles/section.module.scss";
import lata from "../../images/lata.png";
import eazy from "../../images/EAZY.svg";
import { IsMobileDevice } from "../../utils/functions";

export default function EazySection() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    return setIsMobile(IsMobileDevice());
  });

  return (
    <div className={styles.containerE}>
      <div className={styles.imageContainerE}>
        <div className={styles.content}>
          <img className={styles.lata} src={lata} />
          <div className={styles.contentE}>
            <h1 className={styles.headerE}>Utopia#1</h1>
            <img src={eazy} />
            {/* <p className={styles.titleE}>Eazy</p> */}
            <div className={styles.secondRowContainer}>
              <div className={styles.textColumn}>
                {!isMobile ? (
                  <p>
                    Eazy es una cerveza moderna, dorada, matte y fresca, con
                    aroma a frutas cítricas, tropicales y de carozo.
                    <br />
                    <br /> Arranca con dulzor frutal e intensidad para terminar
                    con amargor amigo y un hint cítrico que la hacen Eazy de
                    tomar.
                    <br />
                   
                  </p>
                ) : (
                  <p>
                    Eazy es una cerveza moderna, dorada, matte y fresca, con
                    aroma a frutas cítricas, tropicales y de carozo.
                    <br />
                    <br /> Arranca con dulzor frutal e intensidad para terminar
                    con amargor amigo y un hint cítrico.
                    <br />
                    <br /> Eazy de tomar y volver a tomar.
                  </p>
                )}
              </div>
              <div className={styles.framesColumn}>
                <div className={styles.firstFramesContainer}>
                  <div className={styles.firstFrame}>
                    <h5>Malta de Agricultura Regenerativa</h5>
                  </div>
                  <div className={styles.firstFrame}>
                    <h5>Lúpulos Amarillo, Calypso y Azzaca</h5>
                  </div>
                </div>
                <div className={styles.secondFramesContainer}>
                  <div className={styles.secondFrame}>
                    <h5>Amargor (BU) 16.5</h5>
                  </div>
                  <div className={styles.secondFrame}>
                    <h5>37 Cal. (kcal/100ml)</h5>
                  </div>
                  <div className={styles.secondFrame}>
                    <h5>Alc % 3.0</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.data}>
              <div className={styles.dataItem}>
                <p>Amargor</p>
                <div className={styles.circlesContainer}>
                  <div className={styles.circleFill} />
                  <div className={styles.circleFill} />
                  <div className={styles.circleEmpty} />
                  <div className={styles.circleEmpty} />
                  <div className={styles.circleEmpty} />
                </div>
              </div>
              <div className={styles.dataItem}>
                <p>Cuerpo</p>
                <div className={styles.circlesContainer}>
                  <div className={styles.circleFill} />
                  <div className={styles.circleFill} />
                  <div className={styles.circleEmpty} />
                  <div className={styles.circleEmpty} />
                  <div className={styles.circleEmpty} />
                </div>
              </div>
              <div className={styles.dataItem}>
                <p>Alcohol</p>
                <div className={styles.circlesContainer}>
                  <div className={styles.circleFill} />
                  <div className={styles.circleFill} />
                  <div className={styles.circleEmpty} />
                  <div className={styles.circleEmpty} />
                  <div className={styles.circleEmpty} />
                </div>
              </div>
              <div className={styles.dataItem}>
                <p>Aroma</p>
                <div className={styles.circlesContainer}>
                  <div className={styles.circleFill} />
                  <div className={styles.circleFill} />
                  <div className={styles.circleFill} />
                  <div className={styles.circleFill} />
                  <div className={styles.circleEmpty} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
