import React, { useEffect, useState } from "react";
import '../styles/global.css';
import styles from "../styles/home.module.scss";
import Seo from "../components/seo";
// import Header from "../components/Header";
import UtopiaSection from "../components/Sections/UtopiaSection";
import EazySection from "../components/Sections/EazySection";
import AgriculturaRegenerativa from "../components/Sections/AgriculturaRegenerativaSection";
import ShopSection from "../components/Sections/ShopSection";
import Footer from "../components/Footer";
import FormSection from "../components/Sections/FormSection";
import loadable from "@loadable/component";
import AgeGate from "../components/AgeGate";
import PopUp from "../components/Sections/PopUp";
import BuySection from "../components/Sections/BuySection";
import HomeBanner from "../components/HomeBanner";

const Header = loadable(() => import("../components/Header"));

const IndexPage = () => {
  const [isLegalAge, setIsLegalAge] = useState(false);

  useEffect(() => {
    window.dataLayer.push({
      brand: "Cerveza 27",
      Zone: "SAZ",
      country: "ARG",
      city: "CABA",
      campaign: `${
        window.location.search.includes("?cmp=")
          ? window.location.search.split("?cmp=")[1].split("&")[0]
          : ""
      }`,
      cms: "0",
      event: "pageView",
      language: "es",
      login: false,
      pageName: "Inicio",
      pageType: "Basic page",
      SiteType: "Brand",
      product: "NA",
      sku: "NA",
      userUid: "",
      url: "https://cerveza27.com",
      url_campaign: `${
        window.location.search.includes("utm_campaign=")
          ? window.location.search.split("utm_campaign=")[1]
          : ""
      }`,
      Step_number: "",
    });
  }, []);

  return (
    <div className={styles.superContainer}>
      <Seo title="Home" />
      <AgeGate setIsLegalAge={setIsLegalAge}/>
      <Header />
      {/*<section id="utopia">*/}
      {/*  <UtopiaSection isHideLanding={false} />*/}
      {/*</section>*/}
      <section id="home">
        <HomeBanner/>
      </section>
      <section id={"buy"}>
        <BuySection/>
      </section>
      <section id="eazy">
        <EazySection />
      </section>
      <section id="areg">
        <AgriculturaRegenerativa />
      </section>
      {/* <section id="artfamily">
        <AgriculturaRegenerativa />
      </section> */}
      <section id="shop" style={{ display: "none" }}>
        <ShopSection />
      </section>
      <section
        id="form"
        style={{ display: "none" }}
      >
        <FormSection />
      </section>
      <Footer />
    </div>
  );
};

export default IndexPage;
