import styles from "../../styles/buy-section.module.scss";
import React from "react";
import cans from "../../images/latas.png";
import buyCta from "../../images/buy-cta.png";
import tada from "../../images/tada.png";
import merchCta from "../../images/merch-cta.png";
import merchForm from "../../images/merch-form.png";

const BuySection = () => {
  const handleClick = (eventLabel, componentName, elementText) => {
    window.dataLayer.push({
      event: "GAEvent",
      event_category: "Content",
      event_action: "Boton",
      event_label: eventLabel,
      interaction: true,
      component_name: componentName,
      element_text: elementText,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.buyContainer}>
        <img src={cans} alt={"latas"} className={styles.cans} />
        <a
          href={"https://link.tada.com.ar/3qSL/GRLEcerveza27"}
          target={"_blank"}
          onClick={() => handleClick("TaDá", "boton_tada", "TaDá")}
        >
          <img src={buyCta} alt={"compra"} className={styles.cta} />
          <img src={tada} alt={"tada"} className={styles.tada} />
        </a>
      </div>
      <div className={styles.merchContainer}>
        <a
          href={"/participa"}
          target={"_blank"}
          onClick={() => handleClick("Sorteo Merch", "boton_merch", "Sorteo Merch")}
        >
          <img src={merchCta} alt={"sorteo"} className={styles.cta} />
          <img src={merchForm} alt={"form"} className={styles.form} />
        </a>
      </div>
    </div>
  );
};

export default BuySection;
