import React, { useState } from "react";
import styles from "./../../styles/section.module.scss";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import FormSuccess from "../FormSuccess";
import Checkbox27 from "../Checkbox";
import Select from 'react-select'

const SignupSchema = Yup.object().shape({
    fullName: Yup.string()
        .min(2, "Demasiado corto")
        .max(50, "Excede los caracteres")
        .required("Falta completar"),
    email: Yup.string()
        .email("El email es incorrecto")
        .required("Falta completar"),
    state: Yup.string()
        .max(50, "Excede los caracteres")
        .required("Falta completar"),
    questOne: Yup.string()
        .max(50, "Excede los caracteres")
        .required("Falta completar"),
    questTwo: Yup.string()
        .max(50, "Excede los caracteres")
        .required("Falta completar"),
    checked: Yup.bool().oneOf(
        [true],
        "Los terminos y condiciones deben ser aceptados."
    ),
});
export default function FormSection() {
    const [data, setData] = useState();
    const [terms, setTerms] = useState(false);
    const [errorTerms, setErrorTerms] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    return (
        <div className={styles.containerF}>
            {isSuccess ? (
                <FormSuccess />
            ) : (
                <>
                    <div className={styles.wrap}>
                        <div className={styles.titleContainer}>
                            <h1>27</h1>
                            <h1>Q&A.</h1>
                        </div>
                        <p className={styles.descriptionForm}>
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
                            nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
                            erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci
                            tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo
                            consequat.
                        </p>
                        <Formik
                            initialValues={{
                                fullName: "",
                                genre: "",
                                email: "",
                                state: "",
                                questOne: "",
                                questTwo: "",
                            }}
                            validationSchema={SignupSchema}
                            onSubmit={(values) => {
                                // same shape as initial values
                                if (terms) {
                                    let newValues = {
                                        ...values,
                                    };
                                    let data = {
                                        fullName: newValues.fullName,
                                        genre: newValues.genre,
                                        email: newValues.email,
                                        state: newValues.state,
                                        questOne: newValues.questOne,
                                        questTwo: newValues.questTwo,
                                    };
                                    setData(data);
                                    fetch(
                                        "https://wacerveza27back-prd-stage.azurewebsites.net/27qa",
                                        {
                                            method: "POST",
                                            body: JSON.stringify(data),
                                            // credentials: "include",
                                            headers: {
                                                "Content-Type": "application/json",
                                            },
                                        }
                                    ).then((response) => {
                                        if (response.ok) {
                                            setTimeout(() => {
                                                setIsSuccess(true);
                                            }, 500);
                                        } else {
                                            response.text().then((text) => {
                                                console.error("Error guardando los datos. \n", text);
                                            });
                                        }
                                    });
                                } else {
                                    setErrorTerms(true);
                                }
                            }}
                        >
                            {({ errors, touched, setFieldValue  }) => (
                                <Form className={styles.formContainer}>
                                    <Field
                                        className={styles.inputForm}
                                        name="fullName"
                                        placeholder="Nombre y apellido"
                                    />
                                    {errors.fullName && touched.fullName ? (
                                        <div className={styles.errorContainer}>
                                            {errors.fullName}
                                        </div>
                                    ) : null}
                                    <Field
                                        className={styles.inputForm}
                                        name="genre"
                                        placeholder="Género percibido (opcional)"
                                    />
                                    {errors.genre && touched.genre ? (
                                        <div className={styles.errorContainer}>{errors.genre}</div>
                                    ) : null}
                                    <Field
                                        className={styles.inputForm}
                                        name="email"
                                        placeholder="Email"
                                    />
                                    {errors.email && touched.email ? (
                                        <div className={styles.errorContainer}>{errors.email}</div>
                                    ) : null}

                                    <Field name={'state'}
                                           id={'state'}
                                           component={Select}
                                           className={'reactSelectContainer'}
                                           classNamePrefix={'reactSelect'}
                                           placeholder={'Provincia'}
                                           onChange={(e)=>{setFieldValue('state',e.value)}}
                                           options={
                                               [
                                                   { value: 'BA', label: 'Buenos Aires' },
                                                   { value: 'CABA', label: 'Capital Federal' },
                                                   { value: 'CH', label: 'Chaco' },
                                                   { value: 'CB', label: 'Córdoba' },
                                                   { value: 'CR', label: 'Corrientes' },
                                                   { value: 'ER', label: 'Entre Ríos' },
                                                   { value: 'FO', label: 'Formosa' },
                                                   { value: 'JY', label: 'Jujuy' },
                                                   { value: 'LP', label: 'La Pampa' },
                                                   { value: 'LR', label: 'La Rioja' },
                                                   { value: 'MZ', label: 'Mendoza' },
                                                   { value: 'MI', label: 'Misiones' },
                                                   { value: 'NQN', label: 'Neuquén' },
                                                   { value: 'RN', label: 'Río Negro' },
                                                   { value: 'SA', label: 'Salta' },
                                                   { value: 'SJ', label: 'San Juan' },
                                                   { value: 'SL', label: 'San Luis' },
                                                   { value: 'SE', label: 'Santiago del Estero' },
                                                   { value: 'TF', label: 'Tierra del Fuego' },
                                                   { value: 'TU', label: 'Tucumán' },
                                               ]} />
                                    {errors.state && touched.state ? (
                                        <div className={styles.errorContainer}>{errors.state}</div>
                                    ) : null}
                                    <Field name={'questOne'}
                                           id={'questOne'}
                                           component={Select}
                                           className={'reactSelectContainer'}
                                           classNamePrefix={'reactSelect'}
                                           placeholder={'¿Sabes lo que es la agricultura regenerativa?'}
                                           onChange={(e)=>{setFieldValue('questOne',e.value)}}
                                           options={
                                               [
                                                   { value: 'Si', label: 'Si' },
                                                   { value: 'No', label: 'No' },
                                               ]} />
                                    {errors.questOne && touched.questOne ? (
                                        <div className={styles.errorContainer}>
                                            {errors.questOne}
                                        </div>
                                    ) : null}
                                    <Field name={'questTwo'}
                                           id={'questTwo'}
                                           component={Select}
                                           className={'reactSelectContainer'}
                                           classNamePrefix={'reactSelect'}
                                           placeholder={'¿Reciclas las latas luego de tomarlas?'}
                                           onChange={(e)=>{setFieldValue('questTwo',e.value)}}
                                           options={
                                               [
                                                   { value: 'Siempre', label: 'Siempre' },
                                                   { value: 'A veces', label: 'A veces' },
                                                   { value: 'Nunca', label: 'Nunca' },
                                               ]} />
                                    {errors.questTwo && touched.questTwo ? (
                                        <div className={styles.errorContainer}>
                                            {errors.questTwo}
                                        </div>
                                    ) : null}
                                    {/* <div className={styles.checkbox}>
                    <Field
                      type="checkbox"
                      name="checked"
                      // value={terms}
                      onClick={() => setTerms(!terms)}
                    />
                    <h3 className={styles.termsText}>
                      He leído y acepto bases y condiciones,&nbsp;
                      <a
                        className={styles.link}
                        target="_blank"
                        href="/TerminosYCondiciones27.docx"
                      >
                        términos y condiciones
                      </a>
                      ,&nbsp;
                      <a
                        className={styles.link}
                        target="_blank"
                        href="/PoliticasPrivacidad27.docx"
                      >
                        políticas de privacidad
                      </a>{" "}
                      y activaciones de Marketing.
                    </h3>
                  </div>
                  {errors.checked && touched.checked ? (
                    <div className={styles.errorContainer}>
                      {errors.checked}
                    </div>
                  ) : null} */}
                                    <div className={styles.checkboxContainer}>
                                        <Checkbox27
                                            errorTerms={errorTerms}
                                            setErrorTerms={setErrorTerms}
                                            terms={terms}
                                            setTerms={setTerms}
                                        />
                                        {errorTerms && !terms ? (
                                            <div className={styles.errorContainer}>
                                                Los terminos y condiciones deben ser aceptados.
                                            </div>
                                        ) : null}
                                    </div>
                                    <button className={styles.submitBtn} type="submit">
                                        Enviar
                                    </button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </>
            )}
        </div>
    );
}
